import React from "react";
import { Navigate } from "react-router-dom";
import AuthLogin from "./authentication/login";
import { getAuthUser } from "./components/common";
import Layout from "./layout/layout";
import Dashboard from "./pages/dashboard/dashboard";
import Tajindex from "./pages/tajmahal";
import SettingAngkor from "./StatusUpdate/SettingAngkor";
import SettingCombodia from "./StatusUpdate/SettingCombodia";
import AngkorFee from "./FeeUpdate/AngkorFee";
import CombodiaFee from "./FeeUpdate/CombodiaFee";
import { IndexAngkor } from "./pages/angkor";
import IndexCombodia from "./pages/combodia";
import MailCombodia from "./MailDepartment/Combodiamail";
import { LayoutPreview } from "./layout/layoutPreview";
import PreviewTaj from "./pages/tajmahal/preview";
import PreviewAngkor from "./pages/angkor/preview";
import PreviewTurkey from "./pages/Turkey/preview";
import PreviewIndia from "./pages/India/preview";
import PreviewSrilanka from "./pages/Srilanka/preview";
import Indiaindex from "./pages/India";
import Srilankaindex from "./pages/Srilanka";
import PreviewCambodia from "./pages/combodia/preview";
import PreviewKenya from './pages/kenya/preview';
// import Remark from "./pages/tajmahal/Remark";
import { IndexUserRight } from "./MasterUser";
import FirstDisplay from "./pages/FirstDisplay";
import VietnameIndex from "./pages/vietnam";
import PreviewVet from "./pages/vietnam/preview";
import VietnamFee from "./FeeUpdate/vietnamFee";
import TurkeyFee from "./FeeUpdate/TurkeyFee";
import IndiaFee from "./FeeUpdate/IndiaFee";
import Turkeyindex from './pages/Turkey';
import KenyaIndex from "./pages/kenya";

export const routes = () => [
	// {
	//   path: "/",
	//   element: !getAuthUser() ? <AuthLogin /> : <Navigate to={"/app"} />,
	// },
	{
		path: "/",
		element: !getAuthUser() ? <FirstDisplay /> : <Navigate to={"/app"} />,
	},
	{
		path: "/login",
		element: !getAuthUser() ? <AuthLogin /> : <Navigate to={"/app"} />,
	},
	{
		path: "preview",
		element: !getAuthUser() ? <Navigate to={"/preview"} /> : <LayoutPreview />,
		children: [
			{
				path: "/preview/tajmahal/:id/:tran_id",
				element: <PreviewTaj />,
			},
			{
				path: "/preview/vietnam/:id/:tran_id",
				element: <PreviewVet />,
			},
			{
				path: "/preview/kenya/:id/:tran_id",
				element: <PreviewKenya />,
			},
			{
				path: "/preview/combodia/:temporary_id/:tran_id",
				element: <PreviewCambodia />,
			},
			{
				path: "/preview/angkor/:temporary_id/:tran_id",
				element: <PreviewAngkor />,
			},
			{
				path: "/preview/turkey/:temporary_id/:tran_id",
				element: <PreviewTurkey />,
			},
			{
				path: "/preview/india/:temporary_id/:tran_id",
				element: <PreviewIndia />,
			},
			{
				path: "/preview/Srilanka/:temporary_id/:train_id",
				element: <PreviewSrilanka />,
			},
		],
	},
	{
		path: "app",
		element: !getAuthUser() ? <Navigate to={"/"} /> : <Layout />,
		children: [
			{
				path: "dashboard",
				element: <Dashboard />,
			},
			{
			  path: "/app/tajmahal",
			  element: <Tajindex />,
			},
			// {
			//   path: "/app/india",
			//   element: <Indiaindex />,
			// },
			{
			  path: "/app/turkey",
			  element: <Turkeyindex />,
			},
			{
			  path: "/app/sri-lanka",
			  element: <Srilankaindex />,
			},
			{
				path: "/app/angkor",
				element: <IndexAngkor />,
			},
			{
				path: "/app/cambodia",
				element: <IndexCombodia />,
			},
			{
				path: "/app/india",
				element: <Indiaindex />,
			},
			{
				path: "/app/vietnam",
				element: <VietnameIndex />,
			},
			{
				path: "/app/kenya",
				element: <KenyaIndex />,
			},
			{
				path: "/app/MailCombodia",
				element: <MailCombodia />,
			},
			// {
			//   path: "/app/Tajmail",
			//   element: <Tajmail />,
			// },
			// {
			//   path: "/app/Indiamail",
			//   element: <Indiamail />,
			// },
			// {
			//   path: "/app/SettingTaj",
			//   element: <SettingTaj />,
			// },
			{
				path: "/app/SettingAngkor",
				element: <SettingAngkor />,
			},
			{
				path: "/app/SettingCombodia",
				element: <SettingCombodia />,
			},
			// {
			//   path: "/app/SettingIndia",
			//   element: <SettingIndia />,
			// },
			// {
			//   path: "/app/SettingTurkey",
			//   element: <SettingTurkey />,
			// },
			// {
			//   path: "/app/SettingSrilanka",
			//   element: <SettingSrilanka />,
			// },
			{
				path: "/app/AngkorFee",
				element: <AngkorFee />,
			},
			{
				path: "/app/IndiaFee",
				element: <IndiaFee />,
			},
			{
				path: "/app/CombodiaFee",
				element: <CombodiaFee />,
			},
			{
			  path: "/app/vietnam_fee",
			  element: <VietnamFee />,
			},
			// {
			//   path: "/app/SrilankaFee",
			//   element: <SrilankaFee />,
			// },
			// {
			//   path: "/app/TajFee",
			//   element: <TajFee />,
			// },
			{
			  path: "/app/turkeyFee",
			  element: <TurkeyFee />,
			},
			{
				path: "/app/MasterUser",
				element: <IndexUserRight />,
			},
			// {
			//   path: "/app/MasterUser/Addusers",
			//   element: <Addusers />,
			// },
		],
	},
];
